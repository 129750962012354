<template>
  <b-form
    class="p-2"
    @submit.enter.prevent=""
  >
    <b-form-group
      label="Category Name"
      label-for="name"
    >
      <b-form-input
        id="name"
        v-model="catEdit.name"
        autofocus
        placeholder="Category Name"
      />
    </b-form-group>
    <b-form-group
      label="Category Description"
      label-for="description"
    >
      <b-form-input
        id="description"
        v-model="catEdit.description"
        placeholder="Category Description"
      />
    </b-form-group>
    <b-form-group
      id="fileInput"
    >
      <b-form-file
        ref="fileCta"
        accept="image/jpeg, image/png, image/gif"
        placeholder="Drag&amp;drop or select add Image"
        @input="catEdit.image"
        @change="onChange"
      />
      <div class="border p-2 mt-3">
        <template v-if="preview">
          <img
            :src="preview"
            class="img-fluid"
          >
        </template>
      </div>
    </b-form-group>
    <select-language :selected-language="catEdit" />
    <!-- Form Actions -->
    <div class="d-flex mt-2">
      <b-button
        variant="primary"
        class="mr-2"
        type="submit"
        @click="updateCat(catEdit.id)"
      >
        Save
      </b-button>
      <b-button
        type="button"
        variant="outline-secondary"
      >
        Cancel
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {
  BButton, BForm, BFormInput, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import selectLanguage from '@/common/components/SelectLanguage.vue'

export default {
  components: {
    BButton, BForm, BFormInput, BFormGroup, BFormFile, selectLanguage,
  },
  props: {
    catEdit: {
      type: Object,
      default: () => {},
    },
    updateCat: {
      type: Function,
      default: () => Function,
    },
    preview: {
      type: String,
      default: () => String,
    },
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.catEdit.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
