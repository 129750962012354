<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-card>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end m-2">
            <b-button
              v-b-modal.modal-center
              variant="primary"
            >
              <span class="text-nowrap">Add New Blog Category</span>
            </b-button>
            <!-- modal Add Tag -->
            <b-modal
              id="modal-center"
              ref="add-cat-ser"
              centered
              title="Add Ctegories"
              hide-footer
            >

              <add-category
                :add-cat="addCat"
                :add="add"
              />
            </b-modal>
          </div>
          <b-table
            ref="table"
            :items="myProvider"
            :fields="tableColumns"
            :current-page="currentPage"
            :per-page="perPage"
            class="position-relative"
            responsive
          >
            <template #cell(id)="data">
              <span> # {{ data.item.id }} </span>
            </template>
            <!--  eslint-disable-next-line vue/no-unused-vars -->
            <template #cell(icon)="data">
              <!-- <span> # {{ data.item.id }} </span> -->
              <b-avatar
                variant="info"
                :src="data.item.image"
              />
            </template>
            <template #cell(name)="data">
              <span> {{ data.item.name }}</span>
            </template>
            <template #cell(description)="data">
              <span> {{ data.item.description }}</span>
            </template>
            <template #cell(language)="data">
              <span
                v-if="data.item.language"
              >
                <b-badge variant="dark">{{ data.item.language.name }}</b-badge>
              </span>
            </template>
            <template
              #cell(actions)="data"
            >
              <b-button
                v-b-modal="'modal-edit'"
                variant="outline-primary"
                class="btn-icon m-1"
                md
                @click="editCat(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                class="btn-icon"
                md
                @click="deletCat(data.item.id)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </template>
          </b-table>
          <pagination
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page.sync="currentPage"
          />
          <!-- Edit -->
          <b-modal
            id="modal-edit"
            centered
            title="Edit Category"
            hide-footer
          >
            <edit-category
              :cat-edit="catEdit"
              :update-cat="updateCat"
              :preview="preview"
            />
          </b-modal>
        </b-col>
      </b-card>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import {
  BCard, BCol, BRow, BTable, BButton, VBModal, BForm, BFormInput, BFormGroup, BAvatar, BPagination, BFormFile, BBadge,
} from 'bootstrap-vue'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import VueSweetalert2 from 'vue-sweetalert2'
import pagination from '@/common/components/Pagination.vue'
import addCategory from './AddCategory.vue'
import EditCategory from './EditCategory.vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BCol,
    BBadge,
    BRow,
    BTable,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BAvatar,
    EditCategory,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    BFormFile,
    pagination,
    addCategory,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selectedTag: '',
      rows: [],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      preview: null,
      image: null,
      addCat: {
        name: '',
        description: '',
        image: '',
        language_id: '',
      },
      token: localStorage.getItem('access_token'),
      catEdit: {},
    }
  },
  setup() {
    const tableColumns = [
      { key: 'id', label: '#', sortable: true },
      { key: 'icon', sortable: true, searchable: true },
      { key: 'name', sortable: true, searchable: true },
      { key: 'description', sortable: true, searchable: true },
      { key: 'language', sortable: true, searchable: true },
      { key: 'actions' },
    ]

    return {
      tableColumns,
    }
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.catEdit.image = e.target.files[0]
    },
    myProvider(ctx) {
      const promis = axios.get(`get/blog/category?page=${ctx.currentPage}`)
      return promis.then(res => {
        const items = res.data.success.data
        this.totalRows = res.data.success.total
        return items
      // eslint-disable-next-line no-unused-vars
      }).finally(() => {
        this.loading = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => [])
    },
    add() {
      const formData = new FormData()
      formData.append('name', this.addCat.name)
      formData.append('description', this.addCat.description)
      formData.append('image', this.addCat.image)
      formData.append('language_id', this.addCat.language_id)
      axios.post('add/blog/category', formData).then(res => {
        if (res.status === 200) {
          this.$refs.table.refresh()
          this.$bvModal.hide('modal-center')
          this.addCat = {}
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Added Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    deletCat(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`delete/blog/category/${id}`).then(res => {
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
            this.$refs.table.refresh()
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    async editCat(id) {
      await axios.get(`get/blog/category/${id}`).then(res => {
        this.catEdit = res.data.success
        this.preview = res.data.success.image
      })
    },
    updateCat(id) {
      const formData = new FormData()
      formData.append('name', this.catEdit.name)
      formData.append('description', this.catEdit.description)
      formData.append('image', this.catEdit.image)
      formData.append('language_id', this.catEdit.language_id)
      axios.post(`edit/blog/category/${id}`, formData).then(res => {
        if (res.status === 200) {
          this.$refs.table.refresh()
          this.$bvModal.hide('modal-edit')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Added Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>
